import React from 'react';

function ThankYou() {
  return (
    <>
      <div className="thankyou-form">
        <div>
          <iframe src="https://giphy.com/embed/YlSR3n9yZrxfgVzagm" width="200" height="200" frameBorder="0" className="giphy-embed" allowFullScreen></iframe>
          <h2>Thank You!</h2>
          <p>Your details has been submitted successfully, please close the tab.</p>
        </div>
      </div>
    </>
  );
}

export default ThankYou;
